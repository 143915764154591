.yunnao .box .container .item[data-v-2a7bfde0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.2rem 0;
}
.yunnao .box .container .item-left[data-v-2a7bfde0] {
  width: 50%;
  margin-right: 1.2rem;
}
.yunnao .box .container .item-left .title[data-v-2a7bfde0] {
  font-size: 0.36rem;
  color: var(--themeColor);
  margin-bottom: 0.2rem;
}
.yunnao .box .container .item-left .desc[data-v-2a7bfde0] {
  font-size: var(--f16);
  color: #666666;
  line-height: 0.3rem;
  margin-top: 0.3rem;
}
.yunnao .box .container .item-right img[data-v-2a7bfde0] {
  width: 100%;
  height: 100%;
}
.yunnao .box[data-v-2a7bfde0]:nth-child(2) {
  background-color: #f6f6f6;
}
.yunnao .box:nth-child(2) .container .item[data-v-2a7bfde0] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.yunnao .box:nth-child(2) .container .item-left[data-v-2a7bfde0] {
  margin-right: unset;
  margin-left: 1.2rem;
}